export default {
  登陆注册: {
    大标题: 'You have to believe in yourself. Thats the secret of success.',
    登录: '登录',
    注册: '注册',
    手机号: '手机号',
    密码: '密码',
    邀请码必填: '邀请码(必须填写)',
    邀请码非必填: '邀请码(若无可不填)',
    忘记: '忘记了？',
    还没有账号: '还没有账号吗？点击这里',
    进行注册: '进行注册',
    阅读并同意: '阅读并同意我们的',
    用户协议: '用户协议',
    以及: '以及',
    隐私政策: '隐私政策',
    注册账号: '注册账号',
    输入验证码: '在这输入验证码码',
    设置密码: '设置密码',
    获取验证码: '获取验证码',
    注册并登录: '注册并登录',
    找回密码: '找回密码',
    确认更改并登录: '确认更改并登录',
    请先输入手机号: '请先输入手机号',
    密码修改成功: '密码修改成功!'
  },
  首页: {
    首页: '首页',
    当天: '当天',
    近7天: '近7天',
    近30天: '近30天',
    用户: '用户',
    连续天数: '连续天数',
    累计收益: '累计收益',
    每日打卡利息: '每日打卡利息',
    '45天共获取': '45天共获取'
  },
  自律: {
    自律: '自律',
    剩余未取本金: '剩余未取本金',
    累计获得利息: '累计获得利息',
    累计打卡天数: '累计打卡天数',
    规则说明: '规则说明：',
    打卡日程: '打卡日程',
    今日未打卡: '今日未打卡',
    今日已打卡: '今日已打卡',
    开始自律: '开始自律',
    创建新的打卡项目: '创建新的打卡项目',
    打卡收益历史: '打卡收益历史',
    利息收益: '利息收益',
    打卡收益: '打卡收益',
    输入自定义金额: '输入自定义金额',
    今日存入金额: '今日存入金额',
    立即存入: '立即存入',
    连续打卡: '连续打卡',
    可获得: '可获得',
    打卡凭证上传: '打卡凭证上传',
    点击这里上传: '点击这里上传打卡凭证',
    选择视频照片: '选择传视频或者照片',
    上传提示: '上传提交打卡后，我们将进行审核，请按要求进行打卡，注意留意审核消息提醒，以防审核不通过，错过打卡。',
    提交打卡: '提交打卡',
    最低打卡金额: '最低打卡金额'
  },
  消息: {
    消息: '消息'
  },
  积分: {
    积分: '积分',
    积分兑换: '积分兑换',
    持有积分: '持有积分',
    商品详情: '商品详情',
    立即兑换: '立即兑换',
    总共数量: '总共数量',
    剩余数量: '剩余数量',
    积分记录: '积分记录',
    积分获取记录: '积分获取记录'
  },
  我的: {
    我的: '我的',
    我的钱包: '我的钱包',
    充值提现设置: '充值提现设置',
    账号与安全: '账号与安全',
    成为代理: '成为代理',
    地址设置: '地址设置',
    退出账号: '退出账号',
    关于我们: '关于我们',
    我的自律币: '我的自律币',
    提现: '提现',
    已签到: '已签到',
    未签到: '未签到',
    立即签到: '立即签到',
    签到规则: '签到规则',
    身份认证: '身份认证',
    身份认证设置: '身份认证设置',
    身份信息: '身份信息',
    请填写真实姓名: '请填写真实姓名',
    请填写身份证号: '请填写身份证号',
    已完成认证: '已完成认证',
    提交审核: '提交审核',
    修改登录密码: '修改登录密码',
    登陆密码设置: '登陆密码设置',
    忘记了: '忘记了？',
    输入旧的密码: '输入旧的密码',
    输入新的密码: '输入新的密码',
    请再次输入新密码: '请再次输入新密码',
    修改支付密码: '修改支付密码',
    交易设置: '交易设置',
    绑定银行卡: '绑定银行卡',
    绑定USDT地址: '绑定USDT地址',
    USDT地址设置: 'USDT地址设置',
    输入USDT地址: '输入USDT地址',
    绑定支付宝: '绑定支付宝',
    支付宝设置: '支付宝设置',
    输入支付宝账号: '输入支付宝账号',
    设置支付密码: '设置支付密码',
    支付密码: '支付密码',
    输入旧密码: '输入旧密码',
    输入新密码: '输入新密码',
    再次输入新密码: '再次输入新密码',
    账单记录: '账单记录',
    交易类型: '交易类型',
    提现记录: '提现记录'
  },
  签到: '签到',
  保存: '保存',
  绑定: '绑定',
  修改: '修改',
  完成: '完成',
  暂无数据: '暂无数据',
  没有更多了: '没有更多了',
  '立即注册，解锁更多精彩内容': '立即注册，解锁更多精彩内容',
  '注册/登录': '注册/登录',
  保险理财: '保险理财',
  运单任务: '运单任务',
  订单列表: '订单列表',
  权限不足: '权限不足',
  '标识符不能为空。': '标识符不能为空。',
  ID不能为空: 'ID不能为空',
  '抽奖功能已关闭！': '抽奖功能已关闭！',
  '您今天的抽奖次数已用完！': '您今天的抽奖次数已用完！',
  '提交成功！': '提交成功！',
  '提交失败！': '提交失败！',
  类型不正确: '类型不正确',
  '请您先实名认证后再继续操作！': '请您先实名认证后再继续操作！',
  '绑定银行卡失败,请稍后再试': '绑定银行卡失败,请稍后再试',
  请输入实名认证用户下的真实银行卡号码: '请输入实名认证用户下的真实银行卡号码',
  '该银行卡已被绑定。': '该银行卡已被绑定。',
  绑定成功: '绑定成功',
  '该银行卡不存在！': '该银行卡不存在！',
  安全密码不能为空: '安全密码不能为空',
  '安全密码错误！': '安全密码错误！',
  '解绑成功！': '解绑成功！',
  修改成功: '修改成功',
  '旧密码不能为空。': '旧密码不能为空。',
  '新密码不能为空。': '新密码不能为空。',
  '确定新密码不能为空。': '确定新密码不能为空。',
  您输入的俩次密码不一致: '您输入的俩次密码不一致',
  '旧密码只能包含至多20个字符。': '旧密码只能包含至多20个字符。',
  '新密码只能包含至多20个字符。': '新密码只能包含至多20个字符。',
  '确定新密码只能包含至多20个字符。': '确定新密码只能包含至多20个字符。',
  '旧密码只能包含至少6个字符。': '旧密码只能包含至少6个字符。',
  '新密码只能包含至少6个字符。': '新密码只能包含至少6个字符。',
  '确定新密码只能包含至少6个字符。': '确定新密码只能包含至少6个字符。',
  旧安全密码错误: '旧安全密码错误',
  '旧安全密码不能为空。': '旧安全密码不能为空。',
  '新安全密码不能为空。': '新安全密码不能为空。',
  '确定新安全密码不能为空。': '确定新安全密码不能为空。',
  '您输入的俩次安全密码不一致。': '您输入的俩次安全密码不一致。',
  '旧安全密码只能包含至多20个字符。': '旧安全密码只能包含至多20个字符。',
  '新安全密码只能包含至多20个字符。': '新安全密码只能包含至多20个字符。',
  '确定新安全密码只能包含至多20个字符。': '确定新安全密码只能包含至多20个字符。',
  '旧安全密码只能包含至少6个字符。': '旧安全密码只能包含至少6个字符。',
  '新安全密码只能包含至少6个字符。': '新安全密码只能包含至少6个字符。',
  '确定新安全密码只能包含至少6个字符。': '确定新安全密码只能包含至少6个字符。',
  '您已完成实名认证审核，请勿重复提交！': '您已完成实名认证审核，请勿重复提交！',
  '实名认证失败,请稍后再试': '实名认证失败,请稍后再试',
  请输入真实的姓名和身份证号码: '请输入真实的姓名和身份证号码',
  操作成功: '操作成功',
  请输入正确的手机号码: '请输入正确的手机号码',
  '此手机号已存在。': '此手机号已存在。',
  '此身份证号码已存在。': '此身份证号码已存在。',
  找不到该公告: '找不到该公告',
  '您的申请正在审核中，请耐心等待！': '您的申请正在审核中，请耐心等待！',
  '提交成功,请等待管理员审核！': '提交成功,请等待管理员审核！',
  '系统繁忙,请稍后再试': '系统繁忙,请稍后再试',
  '真实姓名不能为空。': '真实姓名不能为空。',
  '证件正面不能为空。': '证件正面不能为空。',
  '证件反面不能为空。': '证件反面不能为空。',
  '真实姓名只能包含至多50个字符。': '真实姓名只能包含至多50个字符。',
  '证件号码只能包含至多50个字符。': '证件号码只能包含至多50个字符。',
  '证件正面只能包含至多255个字符。': '证件正面只能包含至多255个字符。',
  '证件反面只能包含至多255个字符。': '证件反面只能包含至多255个字符。',
  '领取失败！': '领取失败！',
  '该红包已被领取！': '该红包已被领取！',
  '签到功能暂未开放~': '签到功能暂未开放~',
  '您今天已经签过到啦！': '您今天已经签过到啦！',
  '签到成功！': '签到成功！',
  '提现功能暂未开启！': '提现功能暂未开启！',
  '当前时间段内不能进行提现！': '当前时间段内不能进行提现！',
  '请选择银行卡！': '请选择银行卡！',
  '银行卡错误，请联系客服核查！': '银行卡错误，请联系客服核查！',
  '提现金额不能为空。': '提现金额不能为空。',
  '提现类型不能为空。': '提现类型不能为空。',
  '该提现方式已暂停使用，请选择其他的提现方式！': '该提现方式已暂停使用，请选择其他的提现方式！',
  '您暂未绑定银行卡，请绑定后再试！': '您暂未绑定银行卡，请绑定后再试！',
  '您所提交的提现类型暂未绑定，请绑定后再试！': '您所提交的提现类型暂未绑定，请绑定后再试！',
  '产品信息错误！': '产品信息错误！',
  '该产品已暂停参与购买，请购买其他产品！': '该产品已暂停参与购买，请购买其他产品！',
  该产品秒杀活动暂未开始: '该产品秒杀活动暂未开始',
  该产品秒杀活动已结束: '该产品秒杀活动已结束',
  '购买金额不能为空！': '购买金额不能为空！',
  '当前会员等级不能进行购买此产品！': '当前会员等级不能进行购买此产品！',
  '您的余额不足，请前往充值！': '您的余额不足，请前往充值！',
  不能低于起始购买金额: '不能低于起始购买金额',
  不能高于封顶购买金额: '不能高于封顶购买金额',
  已超过该产品可购买额: '已超过该产品可购买额',
  已经超过改产品可购买次数: '已经超过改产品可购买次数',
  '认购成功！': '认购成功！',
  '购买失败,请联系客服处理！': '购买失败,请联系客服处理！',
  '今天您已经答过题啦！': '今天您已经答过题啦！',
  '题目不能为空！': '题目不能为空！',
  '该题目您已经作答！': '该题目您已经作答！',
  '回答错误！': '回答错误！',
  完成答题: '完成答题',
  '回答正确！': '回答正确！',
  '该商品暂时无法兑换！': '该商品暂时无法兑换！',
  '请输入收货信息！': '请输入收货信息！',
  '兑换失败，请联系在线客服！': '兑换失败，请联系在线客服！',
  '该商品已被兑换完,请兑换其他商品！': '该商品已被兑换完,请兑换其他商品！',
  '您的经验值不足,无法进行兑换！': '您的经验值不足,无法进行兑换！',
  '已超过该商品可兑换次数！': '已超过该商品可兑换次数！',
  '兑换成功！': '兑换成功！',
  '该订单无法确认收货！': '该订单无法确认收货！',
  '确认收货成功！': '确认收货成功！',
  '确认收货失败,请联系客服处理！': '确认收货失败,请联系客服处理！',
  注册功能暂未开放: '注册功能暂未开放',
  当前IP注册量已达到最大值: '当前IP注册量已达到最大值',
  '您当前的手机型号只能注册一个账号！': '您当前的手机型号只能注册一个账号！',
  '手机号码不能为空。': '手机号码不能为空。',
  '密码不能为空。': '密码不能为空。',
  '类型不能为空。': '类型不能为空。',
  '验证码不能为空。': '验证码不能为空。',
  '机型不能为空。': '机型不能为空。',
  '邀请码不能为空。': '邀请码不能为空。',
  '重复密码不能为空。': '重复密码不能为空。',
  '密码只能包含至少6个字符。': '密码只能包含至少6个字符。',
  '密码只能包含至多20个字符。': '密码只能包含至多20个字符。',
  验证码错误: '验证码错误',
  找不到推广员: '找不到推广员',
  '手机唯一标识符不能为空。': '手机唯一标识符不能为空。',
  '组别不能为空。': '组别不能为空。',
  '操作繁忙，请您联系在线客服！': '操作繁忙，请您联系在线客服！',
  '用途不能为空。': '用途不能为空。',
  请不要频繁发送短信: '请不要频繁发送短信',
  短信发送失败: '短信发送失败',
  发送成功: '发送成功',
  退出成功: '退出成功',
  退出失败: '退出失败',
  '充值功能暂未开启！': '充值功能暂未开启！',
  账号或者密码错误: '账号或者密码错误',
  '重复密码的值必须等于"密码"。': '重复密码的值必须等于"密码"。',
  '请勿频繁操作！': '请勿频繁操作！',
  '该金额无法进行充值！': '该金额无法进行充值！',
  最低提现金额是: '最低提现金额是{0}',
  最高充值金额是: '最高充值金额是{0}',
  最低充值金额是: '最低充值金额是{0}',
  '标识符是无效的。': '标识符是无效的。',
  '该证件号码已存在！': '该证件号码已存在！',
  '积分不足,签到可获得积分！': '积分不足,签到可获得积分！',
  '领取成功！': '领取成功！',
  '确定新密码应该包含至少6个字符。': '确定新密码应该包含至少6个字符。',
  '旧密码错误!': '旧密码错误!',
  您输入的俩次安全密码不一致: '您输入的俩次安全密码不一致',
  '操作失败，请您联系在线客服！': '操作失败，请您联系在线客服！',
  恭喜您获得: '恭喜您获得',
  '此手机号码已存在。': '此手机号码已存在。',
  请上传凭证: '请上传凭证',
  '卡号应该包含至少16个字符。': '卡号应该包含至少16个字符。',
  '上传成功！': '上传成功！',
  '该银行卡卡号已存在！': '该银行卡卡号已存在！',
  '【返佣】推荐用户成功，获得奖金': '【返佣】推荐用户成功，获得奖金',
  '【系统】完成实名认证获得奖金': '【系统】完成实名认证获得奖金',
  '【红包】系统赠送获得奖金': '【红包】系统赠送获得奖金',
  '【系统】签到赠送积分': '【系统】签到赠送积分',
  '【系统】签到赠送奖金': '【系统】签到赠送奖金',
  '【返佣】推荐用户达到要求，获得奖金': '【返佣】推荐用户达到要求，获得奖金',
  '【订单】下级购买产品获得积分': '【订单】下级购买产品获得积分',
  '【红包】购买活动产品，获得奖金': '【红包】购买活动产品，获得奖金',
  '【红包】购买活动产品，获得返现': '【红包】购买活动产品，获得返现',
  '【订单】购买产品，扣除余额': '【订单】购买产品，扣除余额',
  '【订单】购买产品赠送积分': '【订单】购买产品赠送积分',
  '【返佣】下级购买产品，获得一级返佣': '【返佣】下级购买产品，获得一级返佣',
  '【返佣】下级购买产品，获得二级返佣': '【返佣】下级购买产品，获得二级返佣',
  '【返佣】下级购买产品，获得项目返佣奖励': '【返佣】下级购买产品，获得项目返佣奖励',
  '【系统】回答正确赠送积分': '【系统】回答正确赠送积分',
  '【系统】回答正确赠送奖金': '【系统】回答正确赠送奖金',
  '【兑换】积分兑换商品': '【兑换】积分兑换商品',
  '【兑换】积分兑换红包成功，获得奖金': '【兑换】积分兑换红包成功，获得奖金',
  '【退本】购买产品周期结束，退还本金': '【退本】购买产品周期结束，退还本金',
  '【红包】购买活动产品周期完成，获得奖金': '【红包】购买活动产品周期完成，获得奖金',
  '【红包】购买活动产品周期完成，获得返现': '【红包】购买活动产品周期完成，获得返现',
  '【收益】购买产品，获得收益': '【收益】购买产品，获得收益',
  '【系统】注册赠送奖金': '【系统】注册赠送奖金',
  '【系统】注册赠送积分': '【系统】注册赠送积分',
  '【系统】余额充值': '【系统】余额充值',
  '【系统】充值赠送': '【系统】充值赠送',
  '【系统】提现扣除余额': '【系统】提现扣除余额',
  '【系统】提现被拒返款': '【系统】提现被拒返款',
  '【系统】订单支付': '【系统】订单支付',
  '【红包】邀请好友领取系统红包': '【红包】邀请好友领取系统红包',
  '【系统】晋升VIP等级，领取系统红包': '【系统】晋升VIP等级，领取系统红包',
  '【后台】管理员操作扣除余额': '【后台】管理员操作扣除余额',
  '【后台】管理员操作添加余额': '【后台】管理员操作添加余额',
  '【后台】管理员操作扣除积分': '【后台】管理员操作扣除积分',
  '【后台】管理员操作添加积分': '【后台】管理员操作添加积分',
  '卡号不能为空。': '卡号不能为空。',
  '开户行不能为空。': '开户行不能为空。',
  '登录已过期，请重新登录': '登录已过期，请重新登录',
  充值: '充值',
  提现: '提现',
  利息: '利息',
  团队报表: '团队报表',
  账户明细: '账户明细',
  实名认证: '实名认证',
  积分商城: '积分商城',
  我的消息: '我的消息',
  联系客服: '联系客服'
}
