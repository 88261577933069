import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
  const _component_van_tabbar = _resolveComponent("van-tabbar");
  return _ctx.active != -1 ? (_openBlock(), _createBlock(_component_van_tabbar, {
    key: 0,
    class: "tabbarBox",
    placeholder: true,
    "safe-area-inset-bottom": true,
    "active-color": "#000",
    modelValue: _ctx.active,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.active = $event)
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.NavList, (i, k) => {
      return _openBlock(), _createBlock(_component_van_tabbar_item, {
        url: '#' + i.path,
        key: k,
        icon: _ctx.active === k ? i.ico2 : i.ico
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(i.name), 1)]),
        _: 2
      }, 1032, ["url", "icon"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue"])) : _createCommentVNode("", true);
}