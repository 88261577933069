export default {
  UPDATETOKEN (state, res) {
    state.token = res
  },
  UPDATEINFO (state, res) {
    state.info = res
  },
  UPDATEADDVIP (state, res) {
    state.addvip = res
  },
  UPDATEADDVIPTYPE (state, res) {
    state.addvip = true
    state.addvipType = res
  },
  'MESSAGE' (state, res) {
    state.chartMessage = res
  },
  'MESSAGELIST' (state, res) {
    state.messageList = res
  },
  'NOTIFYMSG' (state, res) {
    state.notifyMsg = res
  },
  'PLACARDMSG' (state, res) {
    state.placardMsg = res
  }
}
