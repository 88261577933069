import "core-js/modules/es.array.push.js";
import { onMounted, reactive, toRefs } from 'vue';
import { imgDetails, projectList, signTop } from '@/assets/js/api';
import { useRouter } from 'vue-router';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'index',
  inject: ['$imgFilter'],
  setup() {
    const data = reactive({
      active: '1day',
      proList: [],
      incomeList: [],
      daysList: [],
      token: localStorage.getItem('token'),
      banner: []
    }); // 数据
    const {
      push
    } = useRouter();
    const methods = {
      async onload() {
        const banner = await imgDetails({
          pid: '1'
        });
        if (banner.code === 200) {
          data.banner = banner.data;
        }
        const res = await projectList();
        if (res.code === 200) {
          data.proList = res.data;
        }
        // console.log(res)
      },
      async getSignTop() {
        const topRes = await signTop({
          type: data.active
        });
        if (topRes.code === 200) {
          data.incomeList = topRes.data;
        }
      },
      toJump(val, type) {
        if (val) {
          if (type === '1') {
            window.location.href = val;
          } else if (type === '0') {
            push(val);
          }
        }
      }
    }; // 事件
    onMounted(() => {
      methods.onload();
      methods.getSignTop();
      // if (data.token) {
      // }
    });
    return {
      ...toRefs(data),
      ...methods
    };
  }
};