import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './assets/js/i18n'
import './assets/js/rem'
import 'vant/es/toast/style'
import 'vant/es/dialog/style'
import 'vant/es/notify/style'
import 'vant/es/image-preview/style'

async function getLangFun () {
  // var defaltLang = localStorage.getItem('lang')
  // var langListRes = await getLang()
  // if (langListRes.code == 200) {
  //   var isDefalt
  //   var newList = langListRes.data.map((item,index)=>{
  //     if (item.is_default == 1) {
  //       isDefalt = index
  //     }else {
  //       isDefalt = 0
  //     }
  //     return {
  //       type: item.code,
  //       text: item.name,
  //       is_default: item.is_default
  //     }
  //   })
  //   var newList = [
  //     { text: '简体中文', type: 'cn' },
  //     { text: 'English', type: 'en' }
  //   ]
  //     sessionStorage.setItem('langList', JSON.stringify(newList))
  //   var idnex = newList.findIndex(item => item.type == defaltLang)
  //   if (idnex == -1) {
  //     i18n.locale = newList[isDefalt].type
  //     localStorage.setItem('lang', newList[isDefalt].type)
  //   }
  // }
  if (!localStorage.getItem('lang')) {
    localStorage.setItem('lang', 'cn')
  }
}
getLangFun()
const app = createApp(App)
// app.config.globalProperties.$isWeb = false
app.use(store)
app.use(i18n)
app.use(router)
app.mount('#app')
