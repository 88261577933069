import { getUserInfo } from '@/assets/js/api'
// import { showSuccessToast } from 'vant'

export default {
  async ACTgetMyinfo ({ commit }) {
    const res = await getUserInfo()
    commit('UPDATEINFO', res.data)
    if (res.code === 200) {
      // showSuccessToast('更新成功！')
      localStorage.setItem('myInfo', JSON.stringify(res.data))
    }
    return res.code
  },
  getMessage ({ commit }, res) {
    commit('MESSAGE', res)
  },
  getMessageList ({ commit }, res) {
    commit('MESSAGELIST', res)
  },
  getNotifyMsg ({ commit }, res) {
    commit('NOTIFYMSG', res)
  },
  getPlacardMsg ({ commit }, res) {
    commit('PLACARDMSG', res)
  }
}
