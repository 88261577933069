export default {
  token: null,
  info: null,
  addvip: false,
  addvipType: null,
  messageList: null,
  chartMessage: null,
  notifyMsg: null,
  placardMsg: null
}
