import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-62354d2e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "index"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_image = _resolveComponent("van-image");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_image, {
    width: "25%",
    src: require('@/assets/img/logo.png')
  }, null, 8, ["src"]), !_ctx.token ? (_openBlock(), _createElementBlock("button", {
    key: 0,
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.push('/login')),
    class: "blackSmallBtn"
  }, "登录")) : _createCommentVNode("", true), _ctx.$route.meta.billBtn ? (_openBlock(), _createBlock(_component_van_image, {
    key: 1,
    width: "30px",
    height: "30px",
    onClick: _cache[1] || (_cache[1] = $event => _ctx.$router.push(_ctx.$route.meta.billPath)),
    src: require('@/assets/img/ico/historyBtn.png')
  }, null, 8, ["src"])) : _createCommentVNode("", true), _ctx.$route.meta.signBtn ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    class: "signBtn",
    onClick: _cache[2] || (_cache[2] = $event => _ctx.$router.push('/signPage'))
  }, [_createVNode(_component_van_image, {
    width: "20px",
    height: "20px",
    src: require('@/assets/img/ico/sign.svg')
  }, null, 8, ["src"]), _createElementVNode("span", null, _toDisplayString(_ctx.$t('签到')), 1)])) : _createCommentVNode("", true)]);
}