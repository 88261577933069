import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a59b4100"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "chatTitle"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_image = _resolveComponent("van-image");
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  return _openBlock(), _createBlock(_component_van_nav_bar, {
    border: false,
    fixed: true,
    placeholder: true,
    "safe-area-inset-top": true
  }, {
    left: _withCtx(() => [_createVNode(_component_van_image, {
      onClick: $options.$back,
      width: "30px",
      src: require('@/assets/img/ico/back.png')
    }, null, 8, ["onClick", "src"])]),
    title: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t(_ctx.$route.meta.title)), 1)]),
    right: _withCtx(() => [_ctx.$route.meta.billBtn ? (_openBlock(), _createBlock(_component_van_image, {
      key: 0,
      width: "30px",
      height: "30px",
      onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.push(_ctx.$route.meta.billPath)),
      src: require('@/assets/img/ico/historyBtn.png')
    }, null, 8, ["src"])) : _createCommentVNode("", true), _ctx.$route.meta.fontBtn ? (_openBlock(), _createElementBlock("div", {
      key: 1,
      class: "fontBtnBox",
      onClick: _cache[1] || (_cache[1] = $event => _ctx.$router.push(_ctx.$route.meta.fontBtnPath))
    }, _toDisplayString(_ctx.$route.meta.fontBtnCont), 1)) : _createCommentVNode("", true)]),
    _: 1
  });
}