import { reactive, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'index',
  setup() {
    const {
      locale
    } = useI18n();
    const data = reactive({
      showPopover: false,
      actions: [{
        text: '简体中文',
        type: 'cn'
      }, {
        text: 'English',
        type: 'en'
      }],
      language: locale.value,
      lang: localStorage.getItem('lang'),
      token: localStorage.getItem('token')
    }); // 数据
    const methods = {
      onSelect(action) {
        locale.value = action.type;
        data.language = action.text;
        // moment.locale(value.type)
        localStorage.setItem('lang', action.type);
        data.lang = action.type;
        data.showPopover = false;
        location.reload();
      }
    }; // 事件

    return {
      ...toRefs(data),
      ...methods
    };
  }
};