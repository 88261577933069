import { api } from './MD5'

export const configFile = (obj) => {
  return api.get('/api/v1/common/config\n', obj)
}// 公共配置接口

export const uploadImage = (obj) => {
  return api.post('/api/v1/file/images\n', obj, localStorage.getItem('token'), true)
}// 公共上传图片接口

export const imgDetails = (obj) => {
  return api.get('/api/v1/common/img-details\n', obj)
}// 获取站内图片接口

/* --------------用户接口-------------- */
export const login = (obj) => {
  return api.post('/api/v1/site/login\n', obj)
}// 登录

export const register = (obj) => {
  return api.post('/api/v1/site/register\n', obj)
}// 注册

export const upPwd = (obj) => {
  return api.post('/api/v1/site/up-pwd\n', obj)
}// 忘记密码

export const updatePassword = (obj) => {
  return api.post('/api/v1/member/member/update-password\n', obj, localStorage.getItem('token'))
}// 修改登录密码

export const updatePayPwd = (obj) => {
  return api.post('/api/v1/member/member/update-safety-password\n', obj, localStorage.getItem('token'))
}// 修改支付密码

export const forgetPayPwd = (obj) => {
  return api.post('/api/v1/site/up-safety-pwd\n', obj, localStorage.getItem('token'))
}// 忘记支付密码

export const checkPayPwd = (obj) => {
  return api.post('/api/v1/member/member/verify-safety-password\n', obj, localStorage.getItem('token'))
}// 验证旧的支付密码

export const smsCode = (obj) => {
  return api.post('/api/v1/site/sms-code\n', obj)
}// 获取短信验证码

export const logout = (obj) => {
  return api.post('/api/v1/site/logout\n', obj, localStorage.getItem('token'))
}// 退出登录

export const sign = () => {
  return api.post('/api/v1/member/sign\n', '', localStorage.getItem('token'))
}// 用户签到

export const getUserInfo = (obj) => {
  return api.get('/api/v1/member/member\n', obj, localStorage.getItem('token'))
}// 获取用户信息

export const updateMember = (obj) => {
  return api.post('/api/v1/member/member/update-member\n', obj, localStorage.getItem('token'))
}// 实名认证

export const bankCardList = (obj) => {
  return api.get('/api/v1/member/card\n', obj, localStorage.getItem('token'))
}// 银行卡列表

export const addBankCard = (obj) => {
  return api.post('/api/v1/member/card\n', obj, localStorage.getItem('token'))
}// 添加银行卡

export const cardUnbind = (obj) => {
  return api.post('api/v1/member/card/unbind\n', obj, localStorage.getItem('token'))
}// 解绑银行卡

export const addPayMethods = (obj) => {
  return api.post('api/v1/member/account\n', obj, localStorage.getItem('token'))
}// 绑定USDT地址，支付宝账号

export const withdrawBill = (obj) => {
  return api.post('/api/v1/member/withdraw-bill\n', obj, localStorage.getItem('token'))
}//  申请提现接口

export const teamList = (obj) => {
  return api.get('/api/v1/member/member/list\n', obj, localStorage.getItem('token'))
}//  代理列表

/* --------------积分接口-------------- */
export const signGoodsList = (obj) => {
  return api.get('/api/v1/tea/sign-goods-list\n', obj)
}//  签到商品列表

export const signGoodsListDetail = (obj) => {
  return api.get('/api/v1/tea/sign-goods-list/detail\n', obj)
}//  签到商品详情

export const signGoodsBill = (obj) => {
  return api.post('/api/v1/tea/sign-goods-bill\n', obj, localStorage.getItem('token'))
}//  签到兑换商品

export const addPay = (obj) => {
  return api.post('/api/v1/pay\n', obj, localStorage.getItem('token'))
}//  充值订单接口

export const orderList = (obj) => {
  return api.get('/api/v1/tea/sign-goods-bill\n', obj, localStorage.getItem('token'))
}//  订单列表

export const confirmGoods = (obj) => {
  return api.post('/api/v1/tea/sign-goods-bill/over\n', obj, localStorage.getItem('token'))
}//  确认收货

/* --------------项目接口-------------- */
export const projectList = (obj) => {
  return api.get('api/v1/tea/project\n', obj)
}//  项目列表

export const projectDetail = (obj) => {
  return api.get('api/v1/tea/project/detail\n', obj, localStorage.getItem('token'))
}//  项目信息

export const buyProject = (obj) => {
  return api.post('api/v1/tea/bill\n', obj, localStorage.getItem('token'))
}//  创建订单

export const submitCredentials = (obj) => {
  return api.post('api/v1/tea/detail\n', obj, localStorage.getItem('token'))
}//  提交凭证

export const signTop = (obj) => {
  return api.get('api/v1/member/credits-log/list\n', obj, localStorage.getItem('token'))
}//  首页排行榜

export const creditsLog = (obj) => {
  return api.get('/api/v1/member/credits-log\n', obj, localStorage.getItem('token'))
}// 账变列表接口

/* --------------聊天接口-------------- */
export const bindClient = (obj) => {
  return api.post('/api/v1/tea/chat/binding\n', obj, localStorage.getItem('token'))
}// 绑定client_id

export const chatList = () => {
  return api.get('/api/v1/tea/chat\n', '', localStorage.getItem('token'))
}// 聊天列表

export const chatHistory = (obj) => {
  return api.get('/api/v1/tea/chat/chat-history\n', obj, localStorage.getItem('token'))
}// 聊天历史

export const sendMsg = (obj) => {
  return api.post('/api/v1/tea/chat/send-msg\n', obj, localStorage.getItem('token'))
}// 发送消息

export const readMsg = (obj) => {
  return api.post('/api/v1/tea/chat/read-private-msg\n', obj, localStorage.getItem('token'))
}// 读消息

export const notifyMsg = (obj) => {
  return api.get('api/v1/tea/chat/notify\n', obj, localStorage.getItem('token'))
}// 系统通知/打卡审核通知记录   1系统通知，2打卡审核
