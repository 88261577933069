import { reactive, toRefs } from 'vue';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'index',
  inject: ['$back'],
  setup() {
    const data = reactive({}); // 数据
    const methods = {}; // 事件

    return {
      ...toRefs(data),
      ...methods
    };
  }
};