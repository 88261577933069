import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView/index.vue'
import store from '@/store'
const routes = [
  {
    path: '/404',
    component: () => import('../views/NextView/404')
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404'
  },
  {
    path: '/',
    name: 'index',
    component: HomeView,
    meta: {
      topBar: true,
      showLang: true,
      IsLog: false
    }
  },
  {
    path: '/loginRegister',
    name: 'loginRegister',
    component: () => import('@/views/loginRegister'),
    meta: {
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register')
  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import('@/views/forget'),
    meta: {
      IsLog: false
    }
  },
  {
    path: '/checkIn',
    name: '打卡',
    component: () => import('@/views/CheckIn/index'),
    meta: {
      topBar: true,
      billBtn: true,
      billPath: '/incomeList',
      IsLog: true
    }
  },
  {
    path: '/checkInUpload',
    name: 'checkInUpload',
    component: () => import('@/views/CheckIn/upLoad'),
    meta: {
      backBar: true,
      title: '打卡凭证上传',
      IsLog: true
    }
  },
  {
    path: '/incomeList',
    name: 'incomeList',
    component: () => import('@/views/CheckIn/incomeList'),
    meta: {
      backBar: true,
      title: '自律.打卡收益历史',
      IsLog: true
    }
  },
  {
    path: '/chat',
    name: '消息',
    component: () => import('../views/Chat/index'),
    meta: {
      topBar: true,
      IsLog: true
    }
  },
  {
    path: '/chatInfo',
    name: 'chatInfo',
    component: () => import('@/views/Chat/chatPage/chatPage'),
    meta: {
      backBar: true,
      title: '聊天详情',
      IsLog: true
    }
  },
  {
    path: '/integral',
    name: '积分',
    component: () => import('@/views/Integral/index'),
    meta: {
      topBar: true,
      billBtn: true,
      billPath: '/intRecord',
      IsLog: true
    }
  },
  {
    path: '/integralGoods',
    name: 'integralGoods',
    component: () => import('@/views/Integral/goodsDetail'),
    meta: {
      backBar: true,
      title: '积分.商品详情',
      IsLog: true
    }
  },
  {
    path: '/intRecord',
    name: 'intRecord',
    component: () => import('@/views/Integral/intRecord'),
    meta: {
      backBar: true,
      title: '积分.积分记录',
      IsLog: true
    }
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/User/index'),
    meta: {
      topBar: true,
      signBtn: true,
      IsLog: true
    }
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import('@/views/User/wallet'),
    meta: {
      backBar: true,
      title: '我的钱包',
      billBtn: true,
      billPath: '/billRecord'
    }
  },
  {
    path: '/billRecord',
    name: 'billRecord',
    component: () => import('@/views/User/billRecord'),
    meta: {
      backBar: true,
      title: '账单记录'
    }
  },
  {
    path: '/notify',
    name: 'notify',
    component: () => import('@/views/NextView/Notify/index'),
    meta: {
      backBar: true,
      title: '审核通知'
    }
  },
  {
    path: '/placard',
    name: 'placard',
    component: () => import('@/views/NextView/Notify/placard'),
    meta: {
      backBar: true,
      title: '公告'
    }
  },
  {
    path: '/actingCenter',
    name: 'actingCenter',
    component: () => import('@/views/User/Acting/index'),
    meta: {
      backBar: true,
      title: '代理中心',
      fontBtn: true,
      fontBtnCont: '规则',
      fontBtnPath: '/article'
    }
  },
  {
    path: '/InviteRewards',
    name: 'InviteRewards',
    component: () => import('@/views/User/Acting/InviteRewards'),
    meta: {
      backBar: true,
      title: '代理中心'
    }
  },
  {
    path: '/editMyInfo',
    name: 'editMyInfo',
    component: () => import('@/views/User/setting/editMyInfo'),
    meta: {
      backBar: true,
      title: '个人设置'
    }
  },
  {
    path: '/setWithdraw',
    name: 'setWithdraw',
    component: () => import('@/views/User/setting/setWithdraw'),
    meta: {
      backBar: true,
      title: '我的.充值提现设置'
    }
  },
  {
    path: '/bankList',
    name: 'bankList',
    component: () => import('@/views/User/setting/bankList'),
    meta: {
      backBar: true,
      title: '我的.银行卡列表'
    }
  },
  {
    path: '/addBankCard',
    name: 'addBankCard',
    component: () => import('@/views/User/setting/addBankCard'),
    meta: {
      backBar: true,
      title: '我的.添加银行卡'
    }
  },
  {
    path: '/addUSDT',
    name: 'addUSDT',
    component: () => import('@/views/User/setting/addUSDT'),
    meta: {
      backBar: true,
      title: '我的.绑定USDT地址'
    }
  },
  {
    path: '/addAlipay',
    name: 'addAlipay',
    component: () => import('@/views/User/setting/addAlipay'),
    meta: {
      backBar: true,
      title: '我的.绑定支付宝'
    }
  },
  {
    path: '/setPayPwd',
    name: 'setPayPwd',
    component: () => import('@/views/User/setting/setPayPwd'),
    meta: {
      backBar: true,
      title: '我的.设置支付密码'
    }
  },
  {
    path: '/withdrawSetting',
    name: 'withdrawSetting',
    component: () => import('@/views/User/setting/paySettings'),
    meta: {
      backBar: true,
      title: '我的.充值提现设置'
    }
  },
  {
    path: '/securitySettings',
    name: 'securitySettings',
    component: () => import('@/views/User/setting/securitySettings'),
    meta: {
      backBar: true,
      title: '账户安全'
    }
  },
  {
    path: '/verified',
    name: 'verified',
    component: () => import('@/views/User/setting/verified'),
    meta: {
      backBar: true,
      title: '身份认证'
    }
  },
  {
    path: '/forgetLoginPwd',
    name: 'forgetLoginPwd',
    component: () => import('@/views/User/setting/forgetLoginPwd'),
    meta: {
      backBar: true,
      title: '修改登录密码'
    }
  },
  {
    path: '/forgetPayPwd',
    name: 'forgetPayPwd',
    component: () => import('@/views/User/setting/forgetPayPwd'),
    meta: {
      backBar: true,
      title: '忘记支付密码'
    }
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/User/aboutUs/index'),
    meta: {
      backBar: true,
      title: '关于我们'
    }
  },
  {
    path: '/signPage',
    name: 'signPage',
    component: () => import('@/views/User/signPage/index'),
    meta: {
      backBar: true,
      title: '签到领自律币'
    }
  },
  {
    path: '/bankRecharge',
    name: 'bankRecharge',
    component: () => import('@/views/User/recharge/bankRecharge'),
    meta: {
      backBar: true,
      title: '银行卡充值'
    }
  },
  {
    path: '/usdtRecharge',
    name: 'usdtRecharge',
    component: () => import('@/views/User/recharge/usdtRecharge'),
    meta: {
      backBar: true,
      title: 'USDT充值'
    }
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: () => import('@/views/User/withdraw/index'),
    meta: {
      backBar: true,
      title: '提现',
      billBtn: true,
      billPath: '/withdrawRecord'
    }
  },
  {
    path: '/withdrawRecord',
    name: 'withdrawRecord',
    component: () => import('@/views/User/withdraw/withdrawRecord'),
    meta: {
      backBar: true,
      title: '自律币记录'
    }
  },
  {
    path: '/shippingAddress',
    name: 'shippingAddress',
    component: () => import('@/views/User/shippingAddress/index'),
    meta: {
      backBar: true,
      title: '收货地址'
    }
  },
  {
    path: '/setAddress',
    name: 'setAddress',
    component: () => import('@/views/User/shippingAddress/setAddress'),
    meta: {
      backBar: true,
      title: '收货地址'
    }
  },
  {
    path: '/article',
    name: 'article',
    component: () => import('@/views/NextView/Article/index'),
    meta: {
      backBar: true,
      title: '文章'
    }
  },
  {
    path: '/protocol',
    name: 'protocol',
    component: () => import('@/views/NextView/Article/protocol'),
    meta: {
      backBar: true,
      title: '用户协议'
    }
  },
  {
    path: '/protocol',
    name: 'protocol',
    component: () => import('@/views/NextView/Article/protocol'),
    meta: {
      backBar: true,
      title: ''
    }
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import('@/views/NextView/Article/policy'),
    meta: {
      backBar: true,
      title: ''
    }
  },
  {
    path: '/inquiry',
    name: 'inquiry',
    component: () => import('@/views/NextView/iframe/inquiry'),
    meta: {
      backBar: true,
      title: '快递查询'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (store.state.token) {
    if (to.path !== '/login' && to.path !== '/register') {
      next()
    } else {
      router.go(-1)
    }
  } else {
    if (to.path === '/login' || to.path === '/register' || !to.meta.IsLog) {
      next()
    } else {
      router.push('/login')
    }
  }
})
export default router
