import { configFile, bindClient, chatList } from '@/assets/js/api'
import store from '../../store/index'
let cl = true
let sta = true
let time = true
let soketTimes
let stopTime

let sokets = null
let isBind = false

export const soket = async () => {
  let socketLink
  socketLink = localStorage.getItem('socket_link')
  if (!socketLink) {
    const res = await configFile({
      field: ['socket_link']
    })
    if (res.code === 200) {
      socketLink = res.data.socket_link
      localStorage.setItem('socket_link', res.data.socket_link)
    }
  }

  if (sokets == null) {
    sokets = new WebSocket(socketLink)
  }
  if (sokets) {
    const nulls = await chatList()
    let arrData = []

    if (nulls.code === 200) {
      arrData = nulls.data
      store.dispatch('getMessageList', arrData)
    }
  }
  // sokets = new WebSocket('wss://gw6yun.cn:8585')
  if (!localStorage.getItem('memberId')) {
    return
  }
  sokets.onopen = () => {
    if (soketTimes) {
      clearInterval(soketTimes)
    }
    soketTimes = setInterval(() => {
      // sokets.send('ping')
      if (sokets.readyState === WebSocket.OPEN) {
        sokets.send('ping')
      } else {
        run()
      }
    }, 20000)
    // console.log(soketTimes)
  }// 发送请求ping
  sokets.onmessage = async (e) => {
    const data = JSON.parse(e.data)
    // if (time) {
    //   localStorage.setItem('time', Math.round(new Date().getTime() / 1000))
    // }
    if (data.group_type === 'join_group') {
      const obj = store.state.messageList
      const arr = {}
      arr.avatar = data.avatar
      arr.id = data.id
      arr.last_content = data.last_content
      arr.name = data.name
      arr.time = data.time
      arr.type = data.type
      arr.group_type = 'group_type'
      obj.group.push(arr)
      store.dispatch('getMessageList', obj)
    }
    if (data.group_type === 'leave_group') {
      const obj = store.state.messageList

      let res = ''
      for (let i = 0; i < obj.group.length; i++) {
        if (obj.group[i].id === data.id) {
          res = obj.group.slice(i + 1)
        }
      }
      obj.group = res
      store.dispatch('getMessageList', obj)
    }
    if (data.type === 'message') {
      const obj = store.state.messageList
      // const type = JSON.parse(sessionStorage.getItem('myInfo')).type === '1' ? 'member_' : 'manager_'
      const myId = 'member_' + JSON.parse(localStorage.getItem('myInfo')).id
      // let mute

      // const chatType = 'group'
      if (data.group_type === 'group') {
        store.dispatch('getMessage', data)
        const chatType = 'group'
        for (let i = 0; i < obj[chatType].length; i++) {
          if (obj[chatType][i].id + '' === data.id) {
            obj[chatType][i].last_content = data.content
            // obj[chatType][i].name = data.name
            obj[chatType][i].time = data.time + ''
            if (data.from_id !== myId) {
              // obj.allUnreadCount++
              if (obj[chatType][i].unread_count === undefined) {
                obj[chatType][i].unread_count = 1
              } else {
                obj[chatType][i].unread_count++
              }
              // obj[chatType].unread_count++
            }
          }
        }
      } else if (data.group_type === 'friend') {
        store.dispatch('getMessage', data)
        const chatType = 'friend'
        for (let i = 0; i < obj[chatType].length; i++) {
          const fromId = 'manager_' + obj[chatType][i].id
          if (fromId === data.id) {
            obj[chatType][i].last_content = data.content
            // obj[chatType][i].name = data.name
            obj[chatType][i].time = data.time + ''
            if (data.from_id !== myId) {
              // obj.allUnreadCount++
              if (obj[chatType][i].unread_count === undefined) {
                obj[chatType][i].unread_count = 1
              } else {
                obj[chatType][i].unread_count++
              }
              // obj[chatType].unread_count++
            }
          }
        }
      } else if (data.group_type === 'notify') {
        store.dispatch('getPlacardMsg', data)
        const chatType = 'notify'
        obj[chatType].last_content = data.content
        obj[chatType].time = data.time + ''
        if (obj[chatType].unread_count === undefined) {
          obj[chatType].unread_count = 1
        } else {
          obj[chatType].unread_count++
        }
      } else if (data.group_type === 'dk_notify') {
        store.dispatch('getNotifyMsg', data)
        const chatType = 'dk_notify'
        obj[chatType].last_content = data.content
        obj[chatType].time = data.time + ''
        if (obj[chatType].unread_count === undefined) {
          obj[chatType].unread_count = 1
        } else {
          obj[chatType].unread_count++
        }
      }
      store.dispatch('getMessageList', obj)
      // store.dispatch('getMessageList', obj)
      // let chatType = ''
      // if (data.group_type === 'group') {
      //   chatType = 'group'
      // } else {
      //   chatType = 'friend'
      // }
      // for (let i = 0; i < obj[chatType].length; i++) {
      //   const managerId = 'manager_' + obj[chatType][i].id
      //   if (data.id === managerId) {
      //     obj[chatType][i].last_content = data.content
      //     // obj[chatType][i].name = data.name
      //     obj[chatType][i].time = data.time
      //     obj[chatType][i].unread_count += 1
      //   } else if (obj[chatType][i].id === data.id) {
      //     obj[chatType][i].last_content = data.content
      //     // obj[chatType][i].name = data.name
      //     obj[chatType][i].time = data.time
      //     if (data.from_id !== myId) {
      //       // obj.allUnreadCount++
      //       if (obj[chatType][i].unread_count === undefined) {
      //         obj[chatType][i].unread_count = 1
      //       } else {
      //         obj[chatType][i].unread_count++
      //       }
      //       // obj[chatType].unread_count++
      //     }
      //   }
      // }
      // console.log(obj)
      // store.dispatch('getMessageList', obj)
    }
    if (!isBind) {
      if (data.type === 'cmd' && data.order === 'init') {
        const bindRes = await bindClient({
          client_id: data.client_id
        })
        if (bindRes.code === 200) {
          isBind = true
          if (stopTime) {
            sokets.send(JSON.stringify({
              type: 'init',
              token: localStorage.getItem('token'),
              uid: 'member_' + localStorage.getItem('memberId'),
              reconnect_time: stopTime
            }))
          } else {
            sokets.send(JSON.stringify({
              type: 'init',
              token: localStorage.getItem('token'),
              uid: 'member_' + localStorage.getItem('memberId')
            }))
          }
        }
      }
    }
  }
  sokets.onerror = () => {
    console.log('websocket断连')
    // time = false
    run()
  }// 链接失败重连
  sokets.onclose = () => {
    console.log('关闭socket')
    sokets = null
    if (time) {
      stopTime = Math.round(new Date().getTime() / 1000)
      time = false
      isBind = false
    }
    sta = true
    if (sta) {
      sta = false
      run()
    }
  }// 链接关闭
}
export const socketClose = () => {
  console.log('断线')
  sta = false
  if (sokets) {
    sokets.close()
  }
  clearInterval(soketTimes)
}
export const run = () => {
  if (cl) {
    setTimeout(() => {
      soket()
      cl = true
    }, 3000)
  }
  cl = false
}

// const compare = (property) => {
//   return function (a, b) {
//     const value1 = a[property]
//     const value2 = b[property]
//     return value2 - value1
//   }
// }
