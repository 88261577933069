import { onClickLeft, imgFilter, servis
// jumpPage
} from '@/assets/js';
import topBar from '@/components/topBar/index.vue';
import backBar from '@/components/topBar/backNav.vue';
import navBar from '@/components/navBar/index.vue';
import { onMounted, reactive, toRefs
// watch
} from 'vue';
import { useRouter } from 'vue-router';
import { soket } from '@/assets/js/soket.js';
export default {
  components: {
    topBar,
    navBar,
    backBar
  },
  provide() {
    return {
      $back: onClickLeft,
      $imgFilter: imgFilter,
      $servis: servis
    };
  },
  setup() {
    const route = useRouter();
    const data = reactive({
      meta: route.meta,
      soketStatus: true,
      token: localStorage.getItem('token')
    }); // 数据
    const methods = {}; // 事件

    onMounted(() => {
      if (data.token) {
        soket();
        data.soketStatus = false;
      }
    });
    // watch(() => route.currentRoute.value, (to) => {
    //   if (to.name !== 'index' && to.name !== 'login' && to.name !== 'register' && to.name !== 'forget' && to.name !== 'loginRegister') {
    //     if (data.soketStatus && data.token) {
    //       soket()
    //       data.soketStatus = false
    //     } else {
    //       data.soketStatus = true
    //     }
    //   } else {
    //     data.soketStatus = true
    //   }
    // })

    return {
      ...toRefs(data),
      ...methods
    };
  }
};