import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1097bb3c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "index"
};
const _hoisted_2 = {
  class: "cardBox"
};
const _hoisted_3 = {
  class: "cardTitleLine blackCardTop"
};
const _hoisted_4 = {
  class: "cardInnerBox"
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = {
  class: "cardListLeft"
};
const _hoisted_7 = {
  class: "boldFont grayFont"
};
const _hoisted_8 = {
  class: "boldFont"
};
const _hoisted_9 = {
  class: "boldFont"
};
const _hoisted_10 = {
  class: "cardBox swipeCard"
};
const _hoisted_11 = {
  class: "cardTitleLine blackCardTop"
};
const _hoisted_12 = {
  class: "cardInnerBox"
};
const _hoisted_13 = {
  class: "CheckInCard"
};
const _hoisted_14 = {
  class: "CheckInCardTitle"
};
const _hoisted_15 = {
  class: "CheckInCardBottom"
};
const _hoisted_16 = {
  class: "CheckInCardBottomItem"
};
const _hoisted_17 = {
  class: "CheckInCardBottomTitle"
};
const _hoisted_18 = {
  class: "CheckInCardBottomNum"
};
const _hoisted_19 = {
  class: "CheckInCardBottomItem"
};
const _hoisted_20 = {
  class: "CheckInCardBottomTitle"
};
const _hoisted_21 = {
  class: "CheckInCardBottomNum"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_image = _resolveComponent("van-image");
  const _component_van_swipe_item = _resolveComponent("van-swipe-item");
  const _component_van_swipe = _resolveComponent("van-swipe");
  const _component_van_tab = _resolveComponent("van-tab");
  const _component_van_empty = _resolveComponent("van-empty");
  const _component_van_tabs = _resolveComponent("van-tabs");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_swipe, {
    class: "my-swipe",
    autoplay: 3000,
    "indicator-color": "white"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.banner, (ban, index) => {
      return _openBlock(), _createBlock(_component_van_swipe_item, {
        style: {
          "border-radius": "10px"
        },
        onClick: $event => _ctx.toJump(ban.jump_url, ban.jump_type),
        key: index
      }, {
        default: _withCtx(() => [_createVNode(_component_van_image, {
          style: {
            "border-radius": "10px"
          },
          fit: "cover",
          width: "100%",
          height: "100%",
          src: $options.$imgFilter(ban.content)
        }, null, 8, ["src"])]),
        _: 2
      }, 1032, ["onClick"]);
    }), 128))]),
    _: 1
  }), _createVNode(_component_van_tabs, {
    onChange: _cache[0] || (_cache[0] = $event => _ctx.getSignTop()),
    class: "boxLine blackTabs",
    color: '#1B1A20',
    "title-inactive-color": "#1B1A20",
    active: _ctx.active,
    "onUpdate:active": _cache[1] || (_cache[1] = $event => _ctx.active = $event),
    shrink: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tab, {
      name: "1day",
      title: _ctx.$t('首页.当天')
    }, null, 8, ["title"]), _createVNode(_component_van_tab, {
      name: "7day",
      title: _ctx.$t('首页.近7天')
    }, null, 8, ["title"]), _createVNode(_component_van_tab, {
      name: "30day",
      title: _ctx.$t('首页.近30天')
    }, null, 8, ["title"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('首页.用户')), 1), _createElementVNode("span", null, _toDisplayString(_ctx.$t('首页.累计收益')), 1)]), _createVNode(_component_van_swipe, {
      class: "cardListSwipe",
      loop: false,
      autoplay: false,
      "indicator-color": "white"
    }, {
      default: _withCtx(() => [_createVNode(_component_van_swipe_item, null, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_ctx.incomeList.length === 0 ? (_openBlock(), _createBlock(_component_van_empty, {
          key: 0,
          "image-size": "100",
          description: _ctx.$t('暂无数据')
        }, null, 8, ["description"])) : (_openBlock(), _createElementBlock("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.incomeList, (incomeItem, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: incomeItem.member_id,
            class: "cardListItem"
          }, [_createElementVNode("div", _hoisted_6, [_createElementVNode("span", _hoisted_7, _toDisplayString(index + 1), 1), _createElementVNode("span", null, [_createVNode(_component_van_image, {
            width: "35",
            height: "35",
            radius: "14px",
            fit: "cover",
            src: incomeItem.member.head_portrait !== '' ? $options.$imgFilter(incomeItem.member.head_portrait) : require('@/assets/img/user/profile_small.jpg')
          }, null, 8, ["src"])]), _createElementVNode("span", _hoisted_8, _toDisplayString(incomeItem.member.nickname), 1)]), _createElementVNode("div", _hoisted_9, _toDisplayString(incomeItem.income), 1)]);
        }), 128))]))])]),
        _: 1
      })]),
      _: 1
    })])]),
    _: 1
  }, 8, ["active"]), _createVNode(_component_van_swipe, {
    "show-indicators": false,
    class: "boxLine",
    loop: false,
    width: 300
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.proList, proItem => {
      return _openBlock(), _createBlock(_component_van_swipe_item, {
        key: proItem
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("span", null, _toDisplayString(proItem.title + ' (' + proItem.deadline) + "天) ", 1), _createVNode(_component_van_image, {
          onClick: $event => _ctx.$router.push({
            path: '/checkIn',
            query: {
              id: proItem.id
            }
          }),
          width: "25px",
          height: "25px",
          src: require('@/assets/img/ico/more.png')
        }, null, 8, ["onClick", "src"])]), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, [_createElementVNode("span", _hoisted_14, _toDisplayString(proItem.least_amount + '起投 利息' + proItem.income + '%'), 1), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('首页.每日打卡利息')), 1), _createElementVNode("span", _hoisted_18, _toDisplayString(proItem.money), 1)]), _createElementVNode("div", _hoisted_19, [_createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t('首页.45天共获取')), 1), _createElementVNode("span", _hoisted_21, _toDisplayString(proItem.all_money), 1)])])])])])]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  })]);
}